import { ClinicalTrial, Ecrf, Patient, PatientAdmission, SaveFormDataVM, FormData, AuditTrailPage } from "./models";

export const getPatientAdmission: () => Promise<Array<PatientAdmission>> = async () => {
    const response = await fetch("/api/PatientAdmissions", {
        method: "GET",
        headers: {
            Accept: "application/json",
        },
    });

    if (response.status === 200) {
        const info: Array<PatientAdmission> = await response.json();
        return info;
    }

    return [] as Array<PatientAdmission>;
}

export const getPatients: () => Promise<Array<Patient>> = async () => {
    const response = await fetch("/api/Patient", {
        method: "GET",
        headers: {
            Accept: "application/json",
        },
    });

    if (response.status === 200) {
        const info: Array<Patient> = await response.json();
        return info;
    }

    return [] as Array<Patient>;
}

export const postPatient: (patient: Patient) => Promise<boolean> = async (patient: Patient) => {
    const response = await fetch("/api/Patient/Add", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(patient)
    });

    if (response.status === 200) {
        const info: number = await response.json();
        return true;
    }

    return false;
}

export const deletePatient: (patientId: number) => Promise<boolean> = async (patientId: number) => {
    const response = await fetch(`/api/Patient/Delete/${patientId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    if (response.status === 200) {
        return true;
    }

    return false;
}

export const postPatientAdmission: (patientAdmission: PatientAdmission) => Promise<boolean> = async (patientAdmission: PatientAdmission) => {
    const response = await fetch("/api/PatientAdmissions/Add", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(patientAdmission)
    });

    if (response.status === 200) {
        const info: number = await response.json();
        return true;
    }

    return false;
}

export const deletePatientAdmission: (patientAdmissionId: number) => Promise<boolean> = async (patientAdmissionId: number) => {
    const response = await fetch(`/api/PatientAdmissions/Delete/${patientAdmissionId}`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
        },
    });

    if (response.status === 200) {
        return true;
    }

    return false;
}

export const addClinicalTrial: (trial: ClinicalTrial) => Promise<boolean> = async (trial: ClinicalTrial) => {
    const response = await fetch("/api/ClinicalTrial/Add", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        },
        body: JSON.stringify({ title: trial.title, description: trial.description })
        ,
    });

    if (response.status === 200) {
        return true;
    }

    return false;
}

export const getECRFS: () => Promise<Array<Ecrf>> = async () => {
    const response = await fetch(`/api/Forms`, {
        method: "GET",
        headers: {
            Accept: "application/json",
        },
    });

    if (response.status === 200) {
        const info: Array<Ecrf> = await response.json();
        return info;
    }

    return [] as Array<Ecrf>;
}

export const saveFormValues: (formValues: SaveFormDataVM) => Promise<boolean> = async (formValues: SaveFormDataVM) => {
    const response = await fetch("/api/Forms", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        },
        body: JSON.stringify(formValues),
    });

    if (response.status === 200) {
        return true;
    }

    return false;
}

export const getFormValues: (formId: number, sectionId: number, admissionId: number) => Promise<FormData | null> = async (formId: number, sectionId: number, admissionId: number) => {
    const response = await fetch(`/api/Forms/Data?formId=${formId}&sectionId=${sectionId}&admissionId=${admissionId}`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        },
        body: null,
    });

    if (response.status === 200) {
        try {
            return await response.json();
        } catch {
            return null;
        }
    }

    return null;
}


export const getAuditTrail: (skip: number, take: number) => Promise<AuditTrailPage | null> = async (skip: number, take: number) => {
    const response = await fetch(`/api/AuditTrail?skip=${skip}&take=${take}`, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-type': 'application/json',
        },
        body: null,
    });

    if (response.status === 200) {
        try {
            return await response.json();
        } catch {
            return null;
        }
    }

    return null;
}