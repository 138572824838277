import { EditOutlined, SelectOutlined, DeleteOutlined, PlusOutlined, ExclamationCircleOutlined, FormOutlined, ExportOutlined } from "@ant-design/icons";
import { Button, Table, Space, Input, Modal, Form, Row, Select, Card, Grid, Tabs, Checkbox, message } from "antd";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import { ExclusionCriteria, InclusionCriteria, Patient, PatientAdmission } from "../models/models";
import { deletePatient, postPatient, postPatientAdmission } from "../models/serverApi";
import { PatientAdmissionPopup } from "./PatientAdmission";
import { useNavigate } from "react-router-dom";
import TabPane from "antd/es/tabs/TabPane";

export const PatientsTable: React.FC<{ patients: Patient[], patientAdmissions: PatientAdmission[], afterEdit?: (patient?: Patient) => void, afterDelete?: (patient: Patient) => void, selectionButton?: boolean, selectPatient?: (patient?: Patient) => void }> =
    ({ patients, patientAdmissions, afterEdit: onEdit, afterDelete: onDelete, selectionButton, selectPatient }) => {
        const [formVisible, setFormVisible] = useState(false);
        const [admissionFormVisible, setAdmissionFormVisible] = useState(false);
        const [currentPatient, setCurrentPatient] = useState<Patient | undefined>(undefined);
        const [patientAdmissionsFilter, setPatientAdmissionsFilter] = useState("");
        const [currentPatientAdmission, setCurrentPatientAdmission] = useState<PatientAdmission | undefined>(undefined);
        const navigate = useNavigate();
        const screens = Grid.useBreakpoint();
        const [context, setContext] = useContext(AppContext);

        const showForm = (patient: Patient | undefined) => {
            setCurrentPatient(patient);
            setFormVisible(true);
        };

        const showFormAdmission = (patient: Patient | undefined) => {
            setCurrentPatientAdmission({ notes: "", diagnosis: "", patientId: patient?.id } as any);
            setAdmissionFormVisible(true);
        };

        const columns = [
            {
                title: 'Γεν. Κωδικός',
                dataIndex: 'id',
                key: 'id',
            },
            {
                title: 'Κωδικος ασθενούς',
                dataIndex: 'patientName',
                key: 'patientName',
            },
            {
                title: 'Περιγραφή',
                dataIndex: 'patientDescription',
                key: 'patientDescription',

            },
            {
                title: 'Ομάδα ασθενούς',
                render: (values: Patient, record: Patient, index: number) => {
                    return <>{record.patientClass}</>;
                }
            },
            {
                title: 'Κέντρο',
                render: (values: Patient, record: Patient, index: number) => {
                    return <>{record.clinicalTrial?.title}</>;
                }
            },
            {
                title: '',
                key: 'action',
                render: (text: any, record: Patient) => (
                    <Space.Compact>
                        {selectionButton && <Button type="primary" icon={<SelectOutlined />} onClick={() => selectPatient?.(record)}>
                            Επιλογή
                        </Button>}
                        <Button type="primary" icon={<EditOutlined />} onClick={() => showForm(record)}>
                            Επεξεργασία
                        </Button>
                        {/* <Button type="primary" icon={<PlusOutlined />} onClick={() => showFormAdmission(record)}>
                            Προσθήκη εισαγωγής
                        </Button> */}
                        {!selectionButton && <Button type="primary" onClick={() => {
                            setContext(prevContext => {
                                let pa = patientAdmissions?.find(pa => pa.patientId == record.id);
                                return {
                                    ...prevContext,
                                    selection: {
                                        ...prevContext.selection,
                                        patient: record,
                                        admission: pa
                                    } as any
                                };
                            });
                            let ecrf = context?.ecrfs?.find(e => e.trial?.id == context?.selection?.clinicalTrial?.id);
                            navigate(`/ecrf/${ecrf?.id}`);
                        }}>
                            <FormOutlined />Συμπλήρωση
                        </Button>
                        }
                        {/* <Button danger icon={<DeleteOutlined />} onClick={() => {
                            Modal.confirm({
                                title: 'Διαγραφή ασθενή',
                                icon: <ExclamationCircleOutlined />,
                                content: `Είστε σίγουρος οτι θέλετε να διαγράψετε τον ασθενή;`,
                                okText: 'Ναι',
                                cancelText: 'Όχι',
                                onOk: () => { onPatientDelete(record) },
                                onCancel: () => { },
                            });
                        }}>
                            Διαγραφή
                        </Button> */}
                    </Space.Compact >
                ),
            },
        ];

        const onPatientDelete = async (patient: Patient) => {
            await deletePatient(patient.id);
            onDelete?.(patient);
        }

        const onPatientEdit = async (patient: Patient) => {
            if (!await postPatient(patient)) {
                message.error("Συνέβει ένα σφάλμα κατα την αποθήκευση του ασθενή");
            };

            onEdit?.(patient);
        }

        const onPatientAdmissionEdit = async (patientAdmission: PatientAdmission) => {
            await postPatientAdmission(patientAdmission);
            onEdit?.();
        }
        const exportExcel = () => {
            let trialId = context.selection?.clinicalTrial?.id
            window.open(`/api/ClinicalTrial/Export?id=${trialId}`, '_blank');
        }

        return (
            <Card title="Ασθενείς" bordered hoverable className="always" style={{ marginTop: "24px" }}
                extra={<Space.Compact>
                    <Button type="default" onClick={() => { exportExcel(); }}><ExportOutlined /> Εξαγωγή </Button>
                    <Button type="primary" onClick={() => { showForm(undefined); }}><PlusOutlined /> Προσθήκη ασθενή</Button>
                </Space.Compact>
                }>
                <Table columns={columns} dataSource={patients.filter(u => u.patientName.toLowerCase().includes(patientAdmissionsFilter) || u.patientDescription?.toLowerCase().includes(patientAdmissionsFilter))} rowKey="Id"
                    pagination={{ position: ["topRight"], responsive: true, defaultPageSize: 20, total: patients.length, showSizeChanger: true, showQuickJumper: true, showTotal: (total: number) => <Space direction="horizontal" size={5}><Input.Search value={patientAdmissionsFilter} onChange={(e) => { setPatientAdmissionsFilter(e.target.value) }} placeholder="Αναζήτηση" enterButton style={{}} /> <span>{total} Ασθενείς</span></Space> }}
                />
                <PatientPopup visible={formVisible} onCancel={() => setFormVisible(false)} onFinish={(values: any) => { setFormVisible(false); onPatientEdit(values) }} patient={currentPatient} />
                <PatientAdmissionPopup patients={patients} visible={admissionFormVisible} onCancel={() => setAdmissionFormVisible(false)} onFinish={(values: any) => { setAdmissionFormVisible(false); onPatientAdmissionEdit(values); }} patientAdmission={currentPatientAdmission}></PatientAdmissionPopup>
            </Card>
        );
    };

export const PatientPopup: React.FC<{ visible: boolean, onCancel: () => void, onFinish: (patient: Patient) => void, patient?: Patient }> = ({ visible, onCancel, onFinish, patient: patient }) => {
    const [context, setContext] = useContext(AppContext);
    const [form] = Form.useForm();
    const userTrials = context.user?.trials;
    const [activeTab, setActiveTab] = useState("1");


    useEffect(() => {
        if (patient) {
            form.setFieldsValue({ ...patient, agreement: true });
        }
        else {
            form.setFieldsValue({ patientName: "", patientDescription: "", clinicalTrialId: userTrials?.[0]?.id });
        }

        if (!visible) {
            setActiveTab("1");
        }
    }, [patient, visible, userTrials]);

    const requiredCheckbox = (message: string) => {
        return {
            validator: (a: any, value: any) => {
                return value ? Promise.resolve() : Promise.reject(message);
            }
        }
    }

    const notRequiredCheckbox = (message: string) => {
        return {
            validator: (a: any, value: any) => {
                return !value ? Promise.resolve() : Promise.reject(message);
            }
        }
    }

    const getInclusionCriteriaList = () => {
        try {
            let parsedJson: InclusionCriteria = JSON.parse(context?.selection?.clinicalTrial?.inclusionCriteriaJson ?? "");
            return parsedJson.inclusion;
        }
        catch {
            return [];
        }
    }

    const getExclusionCriteriaList = () => {
        try {
            let parsedJson: ExclusionCriteria = JSON.parse(context?.selection?.clinicalTrial?.exclusionCriteriaJson ?? "");
            return parsedJson.exclusion;
        }
        catch {
            return [];
        }
    }

    const onOkClick = () => {
        let fieldList = ["clinicalTrialId", "patientName", "patientDescription", "agreement"];
        if (activeTab == "2") {
            fieldList = getExclusionCriteriaList().map((ic, i) => `exclusion_${i}`);
        }
        else if (activeTab == "3") {
            fieldList = [];
        }

        form
            .validateFields(fieldList.length > 0 ? fieldList : undefined)
            .then(values => {
                if (patient == null && activeTab != "3") {
                    setActiveTab(activeTab == "1" ? "2" : "3");
                    return;
                }

                form.resetFields();
                onFinish({ ...values, id: patient?.id });
            })
            .catch(info => {
                console.log(form.getFieldsError());
                console.log(activeTab, 'Τα δεδομένα δεν είναι έγκυρα', info);
            });
    }

    const onPreviousClick = () => {
        setActiveTab(activeTab == "3" ? "2" : "1");
    }

    const onCancelClick = () => {
        form.resetFields();
        onCancel();
    }

    return (
        <Modal
            title={patient ? 'Επεξεργασία ασθενή' : 'Νέος ασθενής'}
            destroyOnClose
            width={"70%"}
            open={visible}
            onCancel={onCancelClick}
            footer={<Space.Compact>
                <Button onClick={onCancelClick}>Ακύρωση</Button>
                {patient == null &&
                    <Button style={{ marginLeft: 0 }} type="default" onClick={onPreviousClick} disabled={activeTab == "1"}>Προηγούμενο</Button>
                }
                <Button style={{ marginLeft: 0 }} type="primary" onClick={onOkClick}>{(activeTab == "3" || patient != null) ? "Αποθήκευση" : "Επομενο"}</Button>
            </Space.Compact >}
        >
            <Form form={form} layout="vertical">
                <Tabs className="patienttabs" defaultActiveKey="1" activeKey={activeTab}
                // onChange={(k) => { setActiveTab(k); }}
                >
                    <TabPane tab="Στοιχεία ασθενούς" key="1">
                        <Form.Item key="0" name="clinicalTrialId" label="Κλινική δοκιμή" rules={[{ required: true, message: 'Επιλέξτε μια κλινική δοκιμή' }]}>
                            <Select options={userTrials?.map(ut => { return { value: ut.id, label: ut.title } })} />
                        </Form.Item>
                        <Form.Item key="1" name="patientName" label="Κωδικός ασθενούς" rules={[{ required: true, message: 'Εισάγετε το κωδικό του ασθενή' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item key="2" name="patientDescription" label="Περιγραφή" rules={[{ required: false, message: 'Εισάγετε μια περιγραφή' }]}>
                            <Input.TextArea rows={4} />
                        </Form.Item>

                        <Form.Item
                            key="3"
                            name="agreement"
                            valuePropName="checked"
                            rules={[
                                requiredCheckbox("Παρακαλώ για την συγκατάθεση του ασθενή")
                            ]}
                        >
                            <Checkbox>
                                Ο ασθενής συμφωνεί και έχει υπογράψει την <a href={`Uploads/${context?.selection?.clinicalTrial?.patientConsentFile}`} target="_blank">φόρμα συγκατάθεσης</a>
                            </Checkbox>
                        </Form.Item>
                    </TabPane>
                    {!patient && <>
                        <TabPane tab="Κριτήρια αποκλεισμού" key="2">
                            {getExclusionCriteriaList().map((ic, i) => (<Form.Item
                                key={i}
                                style={{ marginBottom: "0px" }}
                                name={`exclusion_${i}`}
                                valuePropName="checked"
                                initialValue={"checked"}
                                rules={[
                                    notRequiredCheckbox("Παρακαλώ ελέγξτε τα κριτήρια αποκλεισμού του ασθενούς")
                                ]}>
                                <Checkbox><span style={{ whiteSpace: "pre-wrap" }}>{ic}</span></Checkbox>
                            </Form.Item>))}
                        </TabPane>
                        <TabPane tab="Κριτήρια επιλογής" key="3">
                            {getInclusionCriteriaList().map((ic, i) => (<Form.Item
                                key={i}
                                style={{ marginBottom: "0px" }}
                                name={`inclusion_${i}`}
                                valuePropName="checked"
                                rules={[
                                    requiredCheckbox("Παρακαλώ ελέγξτε τα κριτήρια εισαγωγής του ασθενούς")
                                ]}>
                                <Checkbox><span style={{ whiteSpace: "pre-wrap" }}>{ic}</span></Checkbox>
                            </Form.Item>))}
                        </TabPane>
                    </>
                    }
                </Tabs>
            </Form>
        </Modal >
    );
};